<template>
  <b-container

    fluid
    class="risk-report"
  >
    <a
      class="link"
      @click="$router.push(`/balanco/visualizar/${data.file.id}`)"
    >
      < Voltar
    </a>

    <b-row>
      <b-col md="12">

        <b-card class="mb-1">
          <h4> {{ data.company.legal_tax_name }} </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
            class="mb-1"
          />

          <b-row class="px-1 justify-content-between">
            <div>
              <h6 class="d-inline">
                CNPJ:
              </h6>
              {{ data.company.tax_id | tax_id }}
            </div>

            <div>
              <h6 class="d-inline">
                ATIVIDADE:
              </h6>
              {{ data.company.activity }}
            </div>

            <div>
              <h6 class="d-inline">
                FUNDAÇÃO:
              </h6>
              {{ data.company.foundation_date | date }}
            </div>

          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <div class="data-container">

      <div class="rating">
        <b-card class="h-100">

          <h4> Rating </h4>
          <div
            style="border-bottom: 6px solid #2E2C6C;"
          />

          <b-row class="px-1 mt-2 justify-content-between">
            <h4> {{ formatDate(data.reanalyze.score.created_at) }} </h4>
            <h4> {{ data.reanalyze.score.grade }}</h4>
          </b-row>

          <Progress
            class="mb-1"
            :animated="false"
            variant="success"
            :value="data.score.calcule"
          />

          <b-row class="px-1 justify-content-between">
            <h4> {{ formatDate(data.score.created_at) }} </h4>
            <h4> {{ data.score.grade }}</h4>
          </b-row>

          <Progress
            :animated="false"
            variant="success"
            :value="data.score.calcule"
          />

        </b-card>

      </div>
      <div class="serasa">
        <b-card>
          <SerasaSummary
            :title="`SERASA ${formatDate(data.created_at)}`"
            :class="`text-left`"
            :pefin="groupSerasa('PEFIN', 'serasa')"
            :refin="groupSerasa('REFIN', 'serasa')"
            :law-suit="groupSerasa('ACAO JUDICIAL', 'serasa')"
            :expired-debts="groupSerasa('DIVIDA VENCIDA', 'serasa')"
            :protest="groupSerasa('PROTESTO', 'serasa')"
            :has-injuction="!!serasa.find(item => item.messageDebts.hasInjuction)"
            :bad-check="!!serasa.find(item => item.badCheck.length)"
          />
        </b-card>
      </div>

      <div class="serasa-reanalyze">
        <b-card>
          <SerasaSummary
            :title="`SERASA ${formatDate(data.reanalyze.created_at)}`"
            :class="`text-left`"
            :pefin="groupSerasa('PEFIN', 'serasaReanalyze')"
            :refin="groupSerasa('REFIN', 'serasaReanalyze')"
            :law-suit="groupSerasa('ACAO JUDICIAL', 'serasaReanalyze')"
            :expired-debts="groupSerasa('DIVIDA VENCIDA', 'serasaReanalyze')"
            :protest="groupSerasa('PROTESTO', 'serasaReanalyze')"
            :has-injuction="!!serasaReanalyze.find(item => item.messageDebts.hasInjuction)"
            :bad-check="!!serasaReanalyze.find(item => item.badCheck.length)"
          />
        </b-card>
      </div>

      <div class="internal-historic">
        <b-card class="h-100">
          <h4> Histórico Interno </h4>
          <b-table
            :fields="[
              { key: 'type', label: '' },
              { key: 'value', label: '' }
            ]"
            :items="internalHistoric"
          >
            <template #cell(value)="currentData">
              <span v-if="(typeof currentData.item.value) === 'boolean'">
                {{ currentData.item.value ? 'Sim' : 'Não' }}
              </span>

              <span v-if="(typeof currentData.item.value) === 'number' && currentData.item.percent">
                {{ currentData.item.value.toFixed(2) + '%' }}
              </span>

              <span v-else>
                {{ currentData.item.value | value }}
              </span>
            </template>
          </b-table>
        </b-card>
      </div>

      <div class="analyst">
        <b-card>
          <h4> Analista </h4>

          <div
            style="border-bottom: 10px solid #2E2C6C;"
            class="mb-1"
          />

          <h6 v-if="data.log.length">
            {{ data.log[data.log.length - 1].user }}
          </h6>
        </b-card>
      </div>

      <div class="report">
        <!-- <b-col md="11"> -->
        <b-card>

          <Report
            class="p-0"
            :ignore-properties="['insolvency_factor']"
            :data="data"
          />
        </b-card>
        <!-- </b-col> -->
      </div>

      <div class="scr-reanalyze">
        <b-card class="h-100">
          <b-row class="px-1 justify-content-between">
            <h4> SCR </h4>
            <h4> {{ formatDateScr(data.reanalyze.scr.scr_analyse.scr) }} </h4>
          </b-row>
          <ScrSummary :data="data.reanalyze" />
        </b-card>

      </div>

      <div class="scr">
        <b-card class="h-100">
          <b-row class="px-1 justify-content-between">
            <h4> SCR </h4>
            <h4> {{ formatDateScr(data.scr.scr_analyse.scr) }} </h4>
          </b-row>
          <ScrSummary :data="data" />
        </b-card>

      </div>

      <div class="divide-service">
        <b-card><h4>
                  Divida Ativa
                </h4>
          <div
            style="border-bottom: 10px solid #2E2C6C;"
            class="mb-1"
          />

          <b-row class="px-1 justify-content-between">
            <p> Estadual: {{ data.active_debt.state | value }}</p>
            <p> Federal: {{ data.active_debt.federal | value }}</p>
            <p> Total: {{ +data.active_debt.federal + +data.active_debt.state | value }}</p>
          </b-row>
        </b-card>
      </div>

    </div>

  </b-container>
</template>

<script>
import {
    BTable, BRow, BCol, BCard, BContainer,
} from 'bootstrap-vue';
import Report from '../Report/Report.vue';

import Progress from '../Progress.vue';

import percentOperation from '@/utils/operations/percentage';

import SerasaSummary from '../Serasa/Summary.vue';

import ScrSummary from '../SCR/Summary.vue';
import { formatDate } from '@/utils';

export default {
    components: {
        BTable,
        Progress,
        BRow,
        BCard,
        BCol,
        Report,
        BContainer,
        SerasaSummary,
        ScrSummary,
    },

    props: {
        data: {
            type: Object,
            required: true,
        },
    },

    computed: {
        internalHistoric() {
            const labels = [
                { type: 'Limite de Crédito', value: this.data.reanalyze.internal_historic.credit_limit },
                { type: 'Volume Operado', value: +this.data.reanalyze.internal_historic.total },
                { type: 'Aberto', value: +this.data.reanalyze.internal_historic.open },
                { type: 'Vencidos', value: +this.data.reanalyze.internal_historic.expired },
                { type: 'Pontual', value: percentOperation(+this.data.reanalyze.internal_historic.total_payed, this.data.reanalyze.internal_historic.on_time), percent: true },
                { type: 'Atraso', value: percentOperation(+this.data.reanalyze.internal_historic.total_payed, this.data.reanalyze.internal_historic.overdue), percent: true },
                { type: 'Recompra', value: percentOperation(+this.data.reanalyze.internal_historic.total_payed, this.data.reanalyze.internal_historic.rebuy), percent: true },
            ];

            if (this.data.balance_type_id === 1) {
                return [];
            }

            return labels;
        },

        groupScr() {
            return this.data.group.map(item => item.report);
        },

        groupReanalyzeScr() {
            return this.data.reanalyze.group.map(item => item.report);
        },

        serasa() {
            const arrAmount = this.data.group.map(item => item.report);

            arrAmount.push(this.data.serasa.report);

            return arrAmount;
        },

        serasaReanalyze() {
            const arrAmount = this.data.reanalyze.group.map(item => item.report);

            arrAmount.push(this.data.reanalyze.serasa.report);

            return arrAmount;
        },
    },

    methods: {
        sum(
            arr,
            key,
        ) {
            return arr.reduce((acc, item) => acc + +item[key], 0);
        },

        formatDate(date) {
            const [_, month, year] = formatDate(date).split('/');
            return `${month}/${year}`;
        },

        formatDateScr(scr) {
            return `${String(scr.month).padStart(2, '0')}/${scr.year}`;
        },

        groupSerasa(disc, key) {
            const value = this[key].reduce((acc, item) => {
                const resume = item.resumeFinancialPendency.find(resume => resume.disc === disc);

                acc.value = (acc.value || 0) + (resume?.value || 0);
                return acc;
            }, {});

            return value;
        },
    },
};
</script>

    <style lang="scss">
        body .risk-report {
            zoom: 90%;
            height: 1080px;
        }

        .link {
            color: white;
        }
    .risk-report  {

        .link {
            color: #e1e1e1;
            transition: all linear .04s;
            &:hover {
                color: #fff
            }
        }

        table {
            thead {
                background-color: #2E2C6C;
                tr th {
                    background-color: transparent !important;
                    color: white
                }

                tr td, th {
                padding: .3rem
            }
            }

            tbody td {
                padding: 1.1rem;
            }

        }
        padding: 1rem 2rem;
        background-color: #2E2C6C;
        border-radius: .5rem;

        .data-container {
            display: grid;
            grid-template-columns: repeat(5, 1fr);
            grid-template-rows: repeat(8, 120px);
            max-width: 100%;
            grid-column-gap: 12px;
            grid-row-gap: 12px;
            max-height: 820px;

            .report {
                grid-area: 1/4/7/6;
                 >div { height: 100%;}
                table {
                    thead {
                        background-color: #2E2C6C;
                        tr th {
                            background-color: transparent !important;
                            color: white
                        }

                        tr td, th {
                        padding: .1rem
                    }
                    }

                tbody td {
                    padding: .6rem;
                }

            }
        }
            .rating { grid-area: 1 / 2 / 3 / 1;  }
            .scr-reanalyze { grid-area: 5 / 2 / 8 / 3; }
            .internal-historic { grid-area: 3 / 1 / 7 / 2; }
            .serasa { grid-area: 1 / 3 / 5 / 4; .card { height: 100%;} }
            .serasa-reanalyze {  grid-area: 1 / 2 / 5 /3; .card { height: 100%;} }
            .scr { grid-area: 5 / 3 / 8 / 3; }
            .divide-service {  grid-area: 7 / 4 / 8 /6; .card { height: 100%;} }
            .analyst {
              grid-area: 7 / 1/ 8 / 2;
            }

            .reanalyze .card {
              background-color: #e1e1e1;
            }
        }

        @media (max-width: 1500px) {
          .data-container {
            max-height: 2000px;
            display: flex;
            flex-direction: column;
            gap: 0px;
          }
        }
    }

    </style>
